import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import ReactMarkdown from "react-markdown"
import SEO from '../components/SEO'
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'

const ComponentName = ({data}) => {
  
  const { id, content, title, description } = data.blog
  let disqusConfig = {
    // url: `${config.siteUrl+location.pathname}`,
    identifier: id,
    title: title,
  }
  return(
    <Layout>
      <SEO title={title} description={description} />
      <section className="blog-template">
        <div className="section-center">
          <article className="blog-content">
            <ReactMarkdown source={content} />
          </article>
          <Link to="/blog" className="btn center-btn">
            blog
          </Link>
          <CommentCount config={disqusConfig} placeholder={'...'} />
          { /* Post Contents */ }
          <Disqus config={disqusConfig} />
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query GetSingleBlog($slug: String) {
    blog: strapiBlogs(slug: { eq: $slug }) {
      id
      content
      title
      description

    }
  }
`

export default ComponentName
